import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import { useState } from "react";
import { useEffect } from "react";
import LocationOnIcon from "@mui/icons-material/FmdGood";
import Drawer from "@mui/material/Drawer";
import InfoIcon from '@mui/icons-material/Info';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SwipeableEdgeDrawer from "./SwipeableEdgeDrawer";
import { mobile, showToast } from "../../App";
import { MenuItem, Select, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import logo from "../assets/logo.jpg"
import { toBanglaYear } from "../utils/translate";


export default function SwipeableTemporaryDrawer(props) {
  const [level, setLevel] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingDefault, setLoadingDefault] = useState(false);
  const [yearlyData, setYearlyData] = useState(null);

  const bengaliYear = new Date().toLocaleDateString('bn-BD', { month: 'long', day: 'numeric', year: 'numeric' });
  const englishYear = new Date().toLocaleDateString('en-BD', { month: 'long', day: 'numeric', year: 'numeric' });

  // var dataLayer2 = new window.google.maps.Data();
  // var dataLayer3 = new window.google.maps.Data();

  const [dataLayer2, setDataLayer2] = useState(new window.google.maps.Data());
  const [dataLayer3, setDataLayer3] = useState(new window.google.maps.Data());


  const removeCurrData = () => {
    // console.log("removeCurrData")

    props.map.data.forEach((feature) => {
      props.map.data.remove(feature);
    });

    props.map.dataLayer2?.forEach((feature) => {
      props.map.dataLayer2.remove(feature);
    });

    props.map.dataLayer3?.forEach((feature) => {
      props.map.dataLayer3.remove(feature);
    });

    dataLayer2.setMap(null);
    dataLayer3.setMap(null);

  }

  const setYearlyGeoJson = (data, map) => {
    // console.log("setYearlyGeoJson", data);

    removeCurrData();

    map.data.loadGeoJson(data?.high);
    map.data.setStyle(function (feature) {
      return {
        fillColor: 'red',
        strokeWeight: 0
      };
    });

    // var dataLayer2 = new window.google.maps.Data();
    dataLayer2.loadGeoJson(data?.mod);
    dataLayer2.setStyle(function (feature) {
      return {
        fillColor: 'yellow',
        strokeWeight: 0
      };
    });
    dataLayer2.setMap(map);

    // var dataLayer3 = new window.google.maps.Data();
    dataLayer3.loadGeoJson(data?.low);
    dataLayer3.setStyle(function (feature) {
      return {
        fillColor: 'green',
        strokeWeight: 0
      };
    });
    dataLayer3.setMap(map);

  }

  const resetDefaultData = (map) => {
    axios.get("https://ews-re-back.onrender.com/link")
      .then((response) => {
        setYearlyData(response.data);
        setYearlyGeoJson(response.data, map);
      }).catch((error) => {
        console.log(error);
      })
  }


  useEffect(() => {
    resetDefaultData(props.map);
  }, [props.map])

  const handleSubmit = async () => {
    setLoading(true);
    const levelFloat = parseFloat(level);

    const regex = /^[+-]?\d+(\.\d+)?$/;
    if (!regex.test(level) || levelFloat <= 0 || level === "") {
      setLoading(false);
      showToast("Please enter a valid water level");
      console.log("invalid water level");
      return;
    }

    const response = await axios.get("https://ews-re-back.onrender.com/link?waterLevel=" + levelFloat)

    // props.map.data.loadGeoJson(response.data.url);
    
    setYearlyData(prevData => {
      return { ...prevData, ...response.data }
    });
    setYearlyGeoJson(response.data, props.map);
    // console.log("handleSubmit", response.data.url);
    setLoading(false);
  }

  const handleYearChange = async (event) => {
    setLoading(true);
    setLevel("");
    const year = event.target.value;

    await axios.get("https://ews-re-back.onrender.com/link?year=" + year)
      .then((response) => {
        setYearlyData(prevData => {
          return { ...prevData, ...response.data }
        });

        setYearlyGeoJson(response.data, props.map);

      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        setLoading(false);
      })
  }


  const handleClear = async () => {
    setLevel("");

    setLoadingDefault(true);
    resetDefaultData(props.map);
    setLoadingDefault(false);
  }
  
  const openNewTab = () => {
    window.open("/#/info", "_blank");
  }

  useEffect(() => {
    console.log("yearlyData", yearlyData);
  }, [yearlyData])

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 300 }}
      role="presentation"
    >
      <List style={{ paddingLeft: "16px", paddingRight: "16px" }}>
        <ListItem style={{ margin: "0", padding: "0" }}>
          {bengaliYear}
        </ListItem>

        <ListItem style={{ margin: "0", padding: "0" }}>
          {englishYear}
        </ListItem>

        <Divider style={{ marginTop: "12px", marginBottom: "12px" }} />

        <ListItem className='tiro-bangla-regular' style={{ margin: "0", padding: "0" }}>
          {yearlyData?.year ?
            toBanglaYear(yearlyData?.year)
            : bengaliYear
          } সালের জন্য ঝুঁকিপ্রবণ এলাকা
        </ListItem>

        <ListItem style={{ margin: "0", padding: "0" }}>
          Erosion-prone Zone for {yearlyData?.year ?? englishYear}
        </ListItem>

        <List >
          <ListItem style={{ padding: "0" }}>
            <span className="dot" style={{ backgroundColor: "red" }}></span>
            <div style={{ paddingLeft: "10px", color: "red" }}>
              High
            </div>
          </ListItem>

          <ListItem style={{ padding: "0" }}>
            <span className="dot" style={{ backgroundColor: "#fccf03" }}></span>
            <div
              style={{ paddingLeft: "10px", color: "#fccf03" }}
            >
              Moderate
            </div>
          </ListItem>

          <ListItem style={{ padding: "0" }}>
            <span className="dot" style={{ backgroundColor: "#1fb862" }}></span>
            <div
              style={{ paddingLeft: "10px", color: "#1fb862" }}
            >
              Low
            </div>
          </ListItem>
        </List>

        {yearlyData &&
          <ListItem style={{ paddingLeft: "0", paddingRight: "0" }}>
            <Select fullWidth
              id="year-input"
              size="small"
              variant="outlined"
              label="Select Year"
              value={yearlyData?.year}
              onChange={handleYearChange}
            >
              {yearlyData?.years?.map((year) => {
                return <MenuItem value={year}>{year}</MenuItem>
              })}
            </Select>
          </ListItem>
        }

        <ListItem style={{ paddingLeft: "0", paddingRight: "0" }}>
          <TextField size="small" id="outlined-basic" variant="outlined" label="Water Level at Bahadurabad" type="number" value={level} onChange={(e) => setLevel(e.target.value)} fullWidth />
          <br />
        </ListItem>

        <ListItem style={{ paddingLeft: "0", paddingRight: "0" }}>
          {/* <Button variant="cotanined" fullWidth onClick={handleSubmit}>Submit</Button> */}
          <LoadingButton fullWidth loading={loading} onClick={handleSubmit} variant="contained">Submit</LoadingButton>
        </ListItem>

        <ListItem style={{ paddingLeft: "0", paddingRight: "0" }}>
          {/* <Button variant="cotanined" fullWidth onClick={handleSubmit}>Submit</Button> */}
          <LoadingButton fullWidth loading={loadingDefault} onClick={handleClear} variant="outlined">Clear</LoadingButton>
        </ListItem>
      </List>
      <Divider />

      <List>
        <ListItem>
          {/* <span className="dot"></span> */}
          <LocationOnIcon style={{ color: "red" }} /> &nbsp;
          Your current location
        </ListItem>

        <ListItem>
          <LocationOnIcon style={{ color: "#7ac943" }} /> &nbsp;
          Your searched location
        </ListItem>
      </List>

      <Divider />

      <div style={{ margin: "16px" }}>
        <img src={logo} alt="logo" style={{ width: "100%" }} />
        <p>Funded by: Ministry of ICT, GoB</p>
        {/* <List>
        <ListItem onClick={openNewTab} style={{ textDecoration: "none", color: "grey", cursor: "pointer" }}>
          <InfoIcon style={{ color: "grey" }} />  &nbsp;

          <p>More about EWS-RE</p>   &nbsp;  &nbsp; <OpenInNewIcon style={{ color: "grey" }} />

        </ListItem>
        </List> */}
        <div onClick={openNewTab} style={{ display: "flex", flexDirection: "row", cursor: "pointer" }}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "10px" }}>
            <InfoIcon style={{ color: "grey" }} />
          </div>

          <p style={{ marginLeft: "10px" }}>More about EWS-RE  </p>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "10px" }}>
            <OpenInNewIcon style={{ color: "grey", marginLeft: "10px" }} />
          </div>
        </div>
      </div>
    </Box>
  );

  return (
    <div>
      {!mobile ? ["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            // open={state[anchor]}
            // onClose={toggleDrawer(anchor, false)}
            variant="permanent"
          >
            <br />
            <h3 className='tiro-bangla-regular' style={{ paddingLeft: "16px", color: "red", margin: "0" }}>নদী ভাঙন ঝুঁকিপ্রবণ এলাকা</h3>
            <b><p style={{ paddingLeft: "16px", color: "red", margin: "0" }}>River Erosion Risk</p></b>
            <Divider style={{ margin: "12px" }} />
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))
        :
        <SwipeableEdgeDrawer>
          {list("bottom")}
        </SwipeableEdgeDrawer>
      }
    </div>
  );
}
